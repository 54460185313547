import React, { Component } from "react"
import Layout from "../components/Layout/layout"

class ShoutbasePage extends Component {
  render() {
    let shoutbaseData = this.props.data.allShoutbaseJson.nodes[0]
    let contributionItems = []
    let i = 0
    shoutbaseData.contributions.forEach(item => {
      contributionItems.push(
        <div
          key={i}
          className="contribution"
          style={{
            backgroundImage: `url(${item.image})`,
            backgroundSize: "100%",
          }}
        >
        <h3>{item.name}</h3>
          <h4>{item.description}</h4>
        </div>
      )
      i++
    })

    return (
      <Layout page={"about"}>
        <div className="main">
          <h1 className="title">{shoutbaseData.title}</h1>
          <h2 className="subtitle">{shoutbaseData.subtitle}</h2>
          <div className="text">{shoutbaseData.text}</div>
        </div>
        <div>
          {contributionItems}
        </div>
      </Layout>
    )
  }
}
export default ShoutbasePage

export const query = graphql`
  query {
    allShoutbaseJson {
      nodes {
        id
        title
        description
        text
        subtitle
        contributions {
          name
          before_image
          after_image
          description
        }
      }
    }
  }
`
